<template>
  <v-row justify="space-around">
    <v-col cols="12" sm="12" md="8">
      <v-row no-gutters justify="space-between">
        <v-col cols="12" md="5">
          <v-card min-height="150px" max-height="150px" width="100%" class="valueIndication">
            <v-row class="pa-3" justify="space-between" align="center" no-gutters>
              <v-col cols="6">
                <p class="text-h6 font-weight-bold">
                  Quantos <br />
                  amigos já aproveitaram?
                </p>
              </v-col>
              <v-col cols="6" class="text-center">
                <p class="text-h4 font-weight-bold">
                  {{ report.indicationQtd }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <v-card width="100%" min-height="150px" max-height="150px" class="pr-3 valueWin">
            <v-row class="pa-3" justify="space-between" align="center" no-gutters>
              <v-col cols="5">
                <p class="text-h6 font-weight-bold">Quanto você <br />
                  já ganhou?</p>
              </v-col>
              <v-col cols="6" class="text-center">
                <p class="text-h4 font-weight-bold">
                  {{ report.indicationTotal | currencyFromCents }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
          <v-card min-height="150px" max-height="150px" width="100%" class="valueMonth">
            <v-row class="pa-3" justify="space-between" align="center" no-gutters>
              <v-col cols="6">
                <p class="text-h6 font-weight-bold">
                  Quantos meses <br />
                  ainda tenho de desconto?
                </p>
              </v-col>
              <v-col cols="6" class="text-center">
                <p class="text-h4 font-weight-bold ma-0">{{
                  report.amountDiscountsRemaining
                  }}</p>
                <p class="subheading font-weight-bold ma-0">{{
                  report.amountDiscountsRemaining > 1 ? 'meses' : 'mês'
                  }}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-4 card" no-gutters>
        <v-col cols="12">
          <v-card class="mb-0 pb-0">
            <v-card-title style="background-color: #0ad18b; color: #ffffff">
              <h2 :class="$vuetify.breakpoint.smAndDown
                  ? 'pt-1 text-h6 font-weight-bold'
                  : 'pt-1 display-1 font-weight-bold'
                " style="color: #ffffff; word-break: keep-all">
                Indique & Ganhe R$ 75,00 para cada amigo que você recomendar ao
                Simplificador
              </h2>
            </v-card-title>
            <v-card-text class="mb-0 pb-1">
              <span class="text-h6 description">
                Você indica seus colegas prestadores de serviço (PJ) e para cada
                indicação você recebe <strong>R$ 75,00</strong> de desconto
                (pode ser acumulativo!), ou seja, quanto mais indicar, mais você
                vai ganhar. Sabe o melhor? O seu amigo ganha 10% de desconto
                <strong>por um ano</strong> Simplificador.
              </span>
              <div class="grid">
                <h3 style="color: #0ad18b" class="font-weight-bold text-h6 title-steps"
                  v-if="$vuetify.breakpoint.smAndUp">
                  3 PASSOS SIMPLES:
                </h3>
                <img class="img" src="@assets/images/backgrounds/img_voucher.png" alt=""
                  v-if="$vuetify.breakpoint.mdAndUp" />

                <v-col cols="12" md="8" class="steps-grid">
                  <div class="d-flex align-center steps py-2" v-for="(item, index) in stepsDescription" :key="index">
                    <v-col cols="3" md="2" class="ml-3">
                      <p class="text-h3 font-weight-bold">
                        {{ item.number }}
                      </p>
                    </v-col>
                    <v-col cols="8" md="8">
                      <p :class="$vuetify.breakpoint.smAndDown
                          ? 'subtitle-1 font-weight-bold'
                          : 'subtitle-1 subheading font-weight-bold '
                        ">
                        {{ item.text }}
                      </p>
                    </v-col>
                  </div>
                </v-col>
              </div>
            </v-card-text>
          </v-card>
          <div>
            <terms></terms>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" sm="12" md="3" class="mt-3">
      <v-row>
        <v-card v-if="$vuetify.breakpoint.mdAndUp" min-height="150px" max-height="150px" width="100%"
          class="valueMonth">
          <v-row class="pa-3" justify="space-between" align="center" no-gutters>
            <v-col cols="6">
              <p class="text-h6 font-weight-bold">
                Quantos meses <br />
                ainda tenho de desconto?
              </p>
            </v-col>
            <v-col cols="6" class="text-center">
              <p class="text-h4 font-weight-bold ma-0">
                {{ report.amountDiscountsRemaining }}
              </p>
              <p class="subheading font-weight-bold ma-0">
                {{ report.amountDiscountsRemaining > 1 ? 'meses' : 'mês' }}
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-col cols="12" class="mb-4">
          <h3 style="font-size: 35px" class="font-weight-bold">
            Compartilhe agora através dos links
          </h3>
          <span class="subheading" v-if="$vuetify.breakpoint.mdAndDown">
            Compartilhe seu cupom de desconto com seus amigos através do
            Facebook, Whatsapp ou como preferir, é só clicar nos botões abaixo.
          </span>
        </v-col>
        <v-tooltip v-model="showCupon" bottom color="sucess" width="100%">
          <template v-slot:activator="{ on }">
            <v-card min-height="100px" width="100%" color="#BDBDBD" v-if="$vuetify.breakpoint.smAndUp"
              @click="isCopyVoucher(voucher.code)" class="card-link">
              <v-card-title class="text-h6 ma-0">
                Seu cupom de desconto é:
                <strong style="text-decoration: underline">
                  {{ voucher.code }}
                </strong>
              </v-card-title>
            </v-card>
          </template>
          <span>Cupom copiado </span>
        </v-tooltip>

        <v-card min-height="100px" width="100%" color="#0088f1" @click="shareFacebook()" target="_blank">
          <v-card-title>
            <v-col cols="2">
              <v-icon large around color="#ffffff">fab fa-facebook</v-icon>
            </v-col>
            <v-col cols="9">
              <p class="text-h6 font-weight-bold">
                Compartilhe seu cupom no Facebook
              </p>
            </v-col>
          </v-card-title>
        </v-card>

        <v-card min-height="100px" color="#36b928" width="100%"
          :href="`https://api.whatsapp.com/send?text=${textShareWhatsapp}`" target="_blank">
          <v-card-title>
            <v-col cols="2">
              <v-icon large color="#ffffff">fab fa-whatsapp</v-icon>
            </v-col>
            <v-col cols="9">
              <p class="text-h6 font-weight-bold">
                Compartilhe seu cupom no Whatsapp
              </p>
            </v-col>
          </v-card-title>
        </v-card>
        <v-tooltip v-model="showCuponLink" bottom color="sucess">
          <template v-slot:activator="{ on }">
            <v-card min-height="100px" color="#6f20d1" @click="isCopyVoucherLink(linkCupon)" class="card-link"
              width="100%">
              <v-card-title>
                <v-col cols="2">
                  <v-icon large color="#ffffff">link</v-icon>
                </v-col>
                <v-col cols="8">
                  <p class="text-h6 font-weight-bold">
                    Copie o link do seu cupom
                  </p>
                </v-col>
              </v-card-title>
            </v-card>
          </template>
          <span>Cupom copiado</span>
        </v-tooltip>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import terms from '@components/Terms/UseVoucher.vue'

const paymentUri = 'voucher'

export default {
  name: 'Voucher',
  components: {
    terms,
  },
  data() {
    return {
      showCuponLink: false,
      showCupon: false,
      originUrl: window.location.origin,
      stepsDescription: [
        {
          number: '1º',
          text: 'Compartilhe nosso cupom de desconto com seus amigos',
        },
        {
          number: '2º',
          text: 'Quando alguém se cadastrar usando o seu cupom você ganha R$ 75,00 reais de desconto em sua próxima mensalidade',
        },
        {
          number: '3º',
          text: 'O desconto é acumulativo, se o valor exceder a sua mensalidade ele será aplicado no próximo mês',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      voucher: 'voucher/voucher',
      report: 'voucher/report',
    }),
    textShareFacebook() {
      let text =
        'Está procurando por uma contabilidade com preços acessíveis e que fale a sua língua? Conheça o Simplificador! Sou cliente e indico =) \n'
      text += `Acesse o link utilizando meu cupom de desconto! \n ${this.linkCupon}`
      return text
    },
    textShareWhatsapp() {
      let text =
        'Você ganhou 10% de desconto no Simplificador! %0A Esse desconto é um presente de amigo! Pra você que está procurando por uma contabilidade que fala a sua língua. Sou cliente e indico =) %0A'
      text += `Pra usar o desconto é só se cadastrar usando esse link %0A ${this.linkCupon}`
      return text
    },
    linkCupon() {
      return `https://app.simplificador.com.br/new-registering?cupom=${this.voucher.code}`
    },
  },

  methods: {
    shareFacebook() {
      FB.ui(
        {
          display: 'popup',
          method: 'share',
          href: this.linkCupon,
          quote: this.textShareFacebook,
        },
        function (response) { }
      )
    },
    isCopyVoucherLink(text) {
      this.$copyText(text).then((e) => {
        this.showCuponLink = true
        setTimeout(() => {
          this.showCuponLink = false
        }, 2000)
      })
    },
    isCopyVoucher(text) {
      this.$copyText(text).then((e) => {
        this.showCupon = true
        setTimeout(() => {
          this.showCupon = false
        }, 2000)
      })
    },
  },
  created() {
    this.$store.dispatch('voucher/getVoucher')
    this.$store.dispatch('voucher/getReport')
  },
}
</script>
<style scoped>
.card-link {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.grid {
  display: grid;
  grid-template-columns: 50% 50% auto;
  grid-template-rows: auto;
}

.img {
  grid-row: 3/3;
  grid-column: 2/3;
  width: 450px;
  height: 450px;
}

.steps {
  background: linear-gradient(to right, #00d692, #5ba353);
  border-radius: 10px;
  min-height: 150px;
  margin-top: 10px;
}

.steps-grid {
  grid-row: 3/3;
  grid-column: 1/3;
}

.title-steps {
  grid-row: 2/2;
  grid-column: 1/3;
}

p {
  color: #ffffff !important;
  width: calc(100% + 1em);
  word-break: break-word;
}

.v-card {
  border-radius: 10px;
  margin-bottom: 20px;
}

.valueIndication {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #66b8ff, #3ca5ff, #0091dc);
}

.valueWin {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #5ba353, #17bb64);
}

.valueMonth {
  display: flex;
  align-items: center;
  background: linear-gradient(to left, #2900dc, #5a5aff);
}

@media screen and (max-width: 1500px) {
  .steps {
    height: 20vh;
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .steps {
    height: 25vh;
    margin-bottom: 10px;
  }
}
</style>
